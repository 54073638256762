@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-color: #333333;
  --text-color-light: #FFFFFF;
  --background-dark: #151515;
  --background-dark-accent: #7E7E7E;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Arial", "Helvetica", sans-sarif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff; 
  color: #333;
  overflow-x: hidden;
}

input:focus{
  outline: none;
}

img {
  /* filter: blur(30px); */
}

a {
  color: #333;
}

a:hover {
  color: black; 
  cursor: pointer;  
}

select {
  min-height: 24px !important;
}

@keyframes fadeIn {
  0% { opacity: 0; } /* Element is completely transparent */
  100% { opacity: 1; } /* Element is fully visible */
}

/* Hide scrollbar for specific elements */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Instagram in-app browser specific styles */
.instagram-browser .landscape\:hidden {
  display: none !important;
}

.instagram-browser .hidden.md\:block.landscape\:block {
  display: block !important;
}

/* Make FloorPlan elements more touch-friendly on Instagram */
.instagram-browser .table-name {
  font-size: 0.5rem !important;
}
